var site = site || {};
var generic = generic || {};
var observerGnavConfig = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true,
  attributeOldValue: true,
  characterDataOldValue: true
};
var mutationObserver;

(function ($) {
  'use strict';

  Drupal.behaviors.gnavCartV1 = {
    $blocks: $(),
    $trigger: $(),
    $counters: $(),
    data: {
      item_count: 0,
      subtotal: '',
      points: 0,
      order_within: '',
      ship_sooner_date: '',
      items: [],
      singular: 0,
      new_items: [],
      display_cart_shade_swatch: Drupal.settings.common.display_cart_shade_swatch
    },
    state: 'empty',
    loaded: false,
    closeNav: false,
    timeoutDelay: 2000,
    closeTimeout: null,

    // Replace dots in the top-level key names the server is giving us.
    // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
    _normalizeResponseKeys: function (items) {
      var replaceKey = function (key) {
        return key.replace(/\./, '_');
      };
      var normalizeArray = function (array) {
        var newArray = [];

        for (var x = 0, alen = array.length; x < alen; x++) {
          var innerObj = {};

          if (typeof array[x] == 'string') {
            // When the array just has a string, just push that.
            newArray.push(array[x]);
          } else {
            // When the array is anything else, just push that.
            for (var innerKey in array[x]) {
              if (array[x].hasOwnProperty(innerKey)) {
                innerObj[replaceKey(innerKey)] = array[x][innerKey];
              }
            }
            newArray.push(innerObj);
          }
        }

        return newArray;
      };
      var out = [];

      for (var i = 0, len = items.length; i < len; i++) {
        out[i] = {};
        for (var key in items[i]) {
          if (items[i].hasOwnProperty(key)) {
            out[i][replaceKey(key)] = items[i][key];
          }
        }
      }

      return out;
    },
    
    _setCloseTimeout: function () {
      var self = this;

      if (self.state !== 'added') {
        return;
      }

      self.closeTimeout = setTimeout(function () {
        if (self.closeNav) {
          $(document).trigger('gnav.readyToClose');
        }
        self.setState();
      }, self.timeoutDelay);
    },

    _clearCloseTimeout: function () {
      var self = this;

      clearTimeout(self.closeTimeout);
    },

    addMutationObserverGnav: function (context) {
      var self = this;
      var $gnavUtilContent = $('.js-gnav-util__content__inner--cart', context);

      mutationObserver = new MutationObserver(function () {
        self.setOverlayHeight(context);
      });

      if ($gnavUtilContent.length > 0) {
        mutationObserver.observe($gnavUtilContent.get(0), observerGnavConfig);
      }
    },

    attach: function (context, settings) {
      var self = this;

      self.$trigger = self.$trigger.add($('.gnav-util--cart', context)).first();
      self.$blocks = self.$blocks.add($('.js-gnav-util__content__inner--cart', context));
      self.$counters = self.$counters.add($('.js-gnav-util__cart-count', context));
      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      var itemCount = Number(site.userInfoCookie.getValue('item_count'));
      
      if (self.$counters.length > 0 && itemCount === 0) {
        var curItemCount = Number(self.$counters[0].innerHTML);
        
        itemCount = curItemCount;
      }
      self.setData(context, { item_count: itemCount });

      if (typeof mutationObserver === 'object') {
        mutationObserver.disconnect();
      }

      self.addMutationObserverGnav(context);

      // Document listeners:
      $(document).on('addToCart.success', function (event, result) {
        if (self.getState() === 'added') {
          return;
        }
        // @setup new brand - this is temporary open/close for testing add to cart
        // a more integrated method with all gnav should be done per brand
        var $cartBlockContainer = $('.js-cart-block-container', context);

        // Revealing the cart overlay, after adding to cart, is now an option in the CMS.
        // Only remove the hidden class if said option was checked.
        if ($cartBlockContainer.hasClass('js-show-after-add-to-cart')) {
          $cartBlockContainer.removeClass('hidden');
        }

        self.addItem(context, result);
      });

      $(document).on('mouseenter', '.js-cart-block-container', self._clearCloseTimeout);
      $(document).on('mouseleave', '.js-cart-block-container', self._setCloseTimeout);

      $(document).on('click', '.cart-block__items__view-bag', function (event) {
        event.preventDefault();

        self._clearCloseTimeout();
        self.setState().render(context);
      });

      $(document).on('click', '.js-gnav-util-trigger--cart', function (event, args) {
        if (Drupal && Drupal.settings && Drupal.settings.disable_cart_overlay) {
          return;
        }
        event.preventDefault();
        var item_count = Number(site.userInfoCookie.getValue('item_count'));
        // Override preventDefault on gnav overlay logic if cart is empty:
        if (self.state === 'empty' && item_count === 0) {
          window.location = $(this).attr('href');

          return true;
        }
        if ($('.gnav-util--cart', context).length) {
          // Do nothing if the cart is empty:
          if ((self.state === 'empty' || self.state === 'added') && item_count === 0) {
            return;
          }

          self.load(context);
          self.setOverlayHeight(context);
          if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
            $(document).trigger('gnav.cart.overlay');
          }
        }
      });

      $(window).resize(
        _.debounce(function () {
          if ($('body', context).hasClass('gnav-active')) {
            self.setOverlayHeight(context);
          }
        }, 250)
      );
    },

    render: function (context) {
      var self = this;
      var rendered = site.template.get({
        name: 'gnav_cart_content',
        data: self.data
      });

      // Some of the field values may actually contain mustache themselves, so
      // this template needs to be run through rendering a second time.
      rendered = site.template.render(rendered, self.data);

      self.$blocks.html(rendered);

      // Update the counters that are outside of the template
      self.$counters.text(self.data.item_count);

      // Render Cart Fineprint Overlay on click
      var $cartFineprintInfoBox = $('.js-cart-fineprint-open', context);

      $cartFineprintInfoBox.once().on('click', function () {
        var renderFineprint = site.template.get({
          name: 'cart_fineprint'
        });

        generic.overlay.launch({
          content: renderFineprint,
          width: 580
        });
      });

      return self;
    },

    load: function (context, force) {
      var self = this;

      if (self.loaded && (!_.isBoolean(force) || !force)) {
        return self;
      }

      $('body').addClass('cart-block-loading');

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $('body').removeClass('cart-block-loading');

          var value = response.getValue();

          if (_.isUndefined(value) || !value) {
            return;
          }

          self.setData(context, {
            subtotal: value.formattedSubtotalNoTax,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: self._normalizeResponseKeys(value.order.items)
          });
          if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
            $(document).trigger('gnav.cart.overlay');
          }

          self.setOverlayHeight(context);
        },
        onError: function () {
          $('body').removeClass('cart-block-loading');
          // @TODO: a failure message should go here.
          self.loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      self.loaded = true;

      return self;
    },

    addItem: function (context, result) {
      var self = this;

      if (_.isUndefined(result) || !result || _.isUndefined(result.trans_data) || _.isUndefined(result.ac_results)) {
        return self;
      }

      var resultType = this.getResultType(result.ac_results);
      var addedItems = '';

      if (resultType != 'sku') {
        addedItems = this.setCollection(result);
      } else {
        addedItems = _.filter(result.ac_results, function (value) {
          var res = value.result;
          var item = res.CARTITEM;

          if (item !== undefined) {
            // Seems very dumb to calculate this on the front end.
            item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

            return item;
          }
        });
      }

      this.setData(context, {
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || self.data.points,
        items: self._normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: self._normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      self.setState('added');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      self.closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      self.loaded = true;
      self._setCloseTimeout();

      return self;
    },

    setOverlayHeight: function (context) {
      var $bagContents = $('.js-cart-block-container', context);
      var $siteHeader = $('.site-header', context);
      // set height of entire overlay to window height, less gnav offset
      var siteHeaderHeight = $siteHeader.outerHeight(true);
      var overlayHeight = $(window).height() - siteHeaderHeight;

      $bagContents.height('auto');
      $bagContents.css('max-height', overlayHeight);

      // set height of product list to available space
      var cartHeaderHeight = $bagContents.find('.cart-block__header').outerHeight(true);
      var cartFooterHeight = $bagContents.find('.cart-block__footer').outerHeight(true);
      var $cartProductsContainer = $bagContents.find('.cart-block__products');
      var productsHeight = overlayHeight - cartHeaderHeight - cartFooterHeight;

      $cartProductsContainer.height('auto');
      $cartProductsContainer.css('max-height', productsHeight);
    },

    // Setters:
    setState: function (newState) {
      var self = this;
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        self.state = self.data.item_count > 0 ? 'nonempty' : 'empty';
      }
      // Sanity check:
      else if (!_.contains(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        self.state = newState;
      }

      self.$blocks.removeClass(stateClasses).addClass(classPrefix + self.state);
      self.$trigger.toggleClass('gnav-util--cart--nonempty', self.state !== 'empty');

      return self;
    },

    setData: function (context, newData) {
      var self = this;

      _.extend(self.data, newData);
      self.data.singular = self.data.item_count === 1;

      self.setState().render(context);

      return self;
    },

    setCollection: function (results) {
      if (!results || !results.ac_results || !results.trans_data) {
        return null;
      }

      var transData = results.trans_data;
      var orderData = transData.order;
      var collectionIds = _.map(results.ac_results, function (obj) {
        var result = obj.result;

        return result && result.COLLECTION_ID && result.CARTITEM ? result.COLLECTION_ID : null;
      });
      var collectionId = _.compact(collectionIds);

      return _.filter(orderData.items, function (item) {
        return _.contains(collectionId, item.COLLECTION_ID);
      });
    },

    // Getters:
    getState: function () {
      var self = this;

      return self.state;
    },

    getData: function (key) {
      var self = this;

      return _.isUndefined(key) ? self.data : self.data[key];
    },

    getResultType: function (results) {
      var type = 'sku';
      var isReplenishment = _.filter(results, function (result) {
        return result.instance == 'alter_replenishment' && result.type == 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    }
  };
})(jQuery);
